/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import {
     Tabs,
     TabsHeader,
     TabsBody,
     Tab,
     TabPanel,
     Typography,
} from '@material-tailwind/react';
import { Square3Stack3DIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import { RiStockFill } from 'react-icons/ri';
import { ImProfile } from 'react-icons/im';

export default () => {
     const data = [
          {
               label: 'Portfolio Login',
               value: 'Portfolio Login',
               icon: ImProfile,
               desc: `Login link to be updated!`,
          },
          {
               label: 'Trade Login',
               value: 'Trade Login',
               icon: RiStockFill,
               desc: `https://ttweb.indiainfoline.com/Trade/Login.aspx`,
          },
     ];
     return (
          <Tabs value="Portfolio Login">
               <TabsHeader>
                    {data.map(({ label, value, icon }) => (
                         <Tab key={value} value={value}>
                              <div className="flex items-center gap-2">
                                   {React.createElement(icon, {
                                        className: 'w-5 h-5',
                                   })}
                                   {label}
                              </div>
                         </Tab>
                    ))}
               </TabsHeader>
               <TabsBody>
                    {data.map(({ value, desc }) => (
                         <TabPanel key={value} value={value}>
                              <Typography className="underline w-full font-semibold text-blue-600 hover:text-blue-800 visited:text-purple-600 cursor-pointer">
                                   {desc}
                              </Typography>
                         </TabPanel>
                    ))}
               </TabsBody>
          </Tabs>
     );
};

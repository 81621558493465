/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import {
     Button,
     Dialog,
     Card,
     CardHeader,
     CardBody,
     CardFooter,
     Typography,
     Input,
     Checkbox,
} from '@material-tailwind/react';
import { BsFillHandThumbsUpFill } from 'react-icons/bs';
import { FaUserPlus } from 'react-icons/fa';
import { LoginControls } from '../../index';

export default (props) => {
     const [open, setOpen] = React.useState(false);

     React.useEffect(() => {
          setOpen(true);
     }, []);

     const handleOpen = () => {
          setOpen((cur) => !cur);
          props.showDialog((cur) => !cur);
     };

     return (
          <>
               <Dialog
                    size="md"
                    open={open}
                    handler={handleOpen}
                    animate={{
                         mount: { scale: 1, y: 0 },
                         unmount: { scale: 0.9, y: -100 },
                    }}
                    className="bg-transparent shadow-none"
               >
                    <Card className="mx-auto w-full max-w-[30rem]">
                         <CardHeader
                              variant="gradient"
                              color="green"
                              className="mb-4 grid h-28 place-items-center"
                         >
                              <Typography variant="h3" color="white">
                                   {<FaUserPlus size={'1.5em'} color="white" />}
                                   Sign Up
                              </Typography>
                         </CardHeader>
                         <CardBody className="flex flex-col gap-4">
                              <div className="w-full">
                                   <Input label="Name" />
                              </div>
                              <div className="w-full">
                                   <Input label="Email" />
                              </div>
                              <div className="w-full">
                                   <Input label="Phone" />
                              </div>
                              <Checkbox
                                   id="ripple-on"
                                   label="By checking the box, I agree to the terms and conditions"
                                   ripple={true}
                              />
                              <div className="w-full flex items-center justify-center gap-4">
                                   <Button
                                        variant="gradient"
                                        size="md"
                                        ripple={true}
                                        color="green"
                                        className="flex items-center gap-3"
                                   >
                                        {
                                             <BsFillHandThumbsUpFill size="1.5em" />
                                        }
                                        Submit
                                   </Button>
                              </div>
                         </CardBody>
                         <CardFooter className="pt-0">
                              <Button
                                   variant="gradient"
                                   onClick={handleOpen}
                                   fullWidth
                                   ripple={true}
                                   className="rounded-full"
                              >
                                   Close
                              </Button>
                         </CardFooter>
                    </Card>
               </Dialog>
          </>
     );
};

/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import { motion, useInView, useAnimate, useAnimation } from 'framer-motion';

export default ({ children, width = 'fit-content' }) => {
     const ref = React.useRef(null);
     const isInView = useInView(ref, { once: true });
     const mainControls = useAnimation();
     const slideControls = useAnimation();

     React.useEffect(() => {
          if (isInView) {
               mainControls.start('visisble');
               slideControls.start('visible');
          }
     }, [isInView]);

     return (
          <>
               <div ref={ref} className="relative fit-content">
                    <motion.div
                         variants={{
                              hidden: { opacity: 0, y: 75 },
                              visible: { opacity: 1, y: 0 },
                         }}
                         initial="hidden"
                         animate={mainControls}
                         transition={{ duration: 0.5, delay: 0.25 }}
                    >
                         {children}
                    </motion.div>
                    <motion.div
                         variants={{
                              hidden: { left: 0 },
                              visible: { left: '100%' },
                         }}
                         initial="hidden"
                         animate={slideControls}
                         transition={{ duration: 0.5, ease: 'easeIn' }}
                         style={{
                              position: 'absolute',
                              top: 2,
                              bottom: 2,
                              left: 0,
                              right: 0,
                              background: '#ffff',
                              zIndex: 20,
                         }}
                    />
               </div>
          </>
     );
};

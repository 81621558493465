import { Chip, Typography } from '@material-tailwind/react';
import { FcFeedback, FcPhone } from 'react-icons/fc';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
     const handleEmail = () => {
          window.location.href = 'mailto:admin@whiteknightwealth.in';
     };
     return (
          <footer className="flex w-full flex-row flex-wrap items-center justify-center gap-y-6 gap-x-12 border-t border-blue-gray-50 py-6 text-center md:justify-between mt-[10vw]">
               <Typography
                    color="blue-gray"
                    className="font-normal text-blue-700"
               >
                    &copy; White Knight Financial Services. All rights reserved
               </Typography>
               <ul className="flex flex-wrap items-center gap-y-2 gap-x-8">
                    <li>
                         <Chip
                              icon={<FcPhone size={'2em'} />}
                              value={'+91 9962055040'}
                              className="gap-4 cursor-pointer bg-gray-200 text-slate-500 text-center hover:text-blue-500 focus:text-blue-500"
                         />
                    </li>
                    <li>
                         <Chip
                              icon={<FcFeedback size={'2em'} />}
                              value={'admin@whiteknightwealth.in'}
                              onClick={handleEmail}
                              className="gap-4 cursor-pointer lowercase bg-gray-200 text-slate-500 text-center hover:text-blue-500 focus:text-blue-50 hover:font-bold"
                         />
                    </li>
                    {/* <li>
                         <Typography
                              as="a"
                              href="#"
                              color="blue-gray"
                              className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500"
                         >
                              Contribute
                         </Typography>
                    </li>
                    <li>
                         <Typography
                              as="a"
                              href="#"
                              color="blue-gray"
                              className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500"
                         >
                              Contact Us
                         </Typography>
                    </li> */}
               </ul>
          </footer>
     );
};

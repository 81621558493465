/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import {
     Navbar,
     MobileNav,
     Button,
     IconButton,
     Chip,
     Tooltip,
} from '@material-tailwind/react';
import { AtSymbolIcon } from '@heroicons/react/24/outline';
import { GiReceiveMoney } from 'react-icons/gi';
import { GoPasskeyFill } from 'react-icons/go';
import { FaUserPlus } from 'react-icons/fa';
import { IoHome } from 'react-icons/io5';
import { BsLightbulbOffFill, BsLightbulbFill } from 'react-icons/bs';
import { Link } from 'react-scroll';
import { motion, useScroll } from 'framer-motion';
import { Screen, AppControls } from '../..';

export default ({ switchTheme, showLogin, showSignUp }) => {
     const [openNav, setOpenNav] = React.useState(false);
     const [theme, setTheme] = React.useState(null);
     const [showInvestment, setShowInvestment] = React.useState(false);
     const [showContactus, setShowContactUs] = React.useState(false);

     React.useEffect(() => {
          window.addEventListener(
               'resize',
               () => window.innerWidth >= 960 && setOpenNav(false)
          );
     }, []);
     const handleThemeChange = () => {
          setTheme(theme === 'dark' ? 'light' : 'dark');
          switchTheme(theme === 'dark' ? 'light' : 'dark');
     };
     const handleShowInvestment = () => {
          setShowInvestment((cur) => !cur);
          setShowContactUs(false);
     };
     const handleShowContactUs = () => {
          setShowContactUs((cur) => !cur);
          setShowInvestment(false);
     };
     const handleShowHome = () => {
          setShowContactUs(false);
          setShowInvestment(false);
     };

     const handleShowLogin = () => {
          showLogin(true);
     };

     const hanldeShowSignUp = () => {
          showSignUp(true);
     };

     const navList = (
          <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
               {!showInvestment && (
                    <Link
                         to="investmentdesk"
                         spy={true}
                         smooth={true}
                         offset={-100}
                         duration={500}
                    >
                         <Chip
                              value="Investment Desk"
                              icon={<GiReceiveMoney size="1.5em" />}
                              variant={'ghost'}
                              className="flex items-center rounded-full dark:text-white dark:border-white ..."
                         />
                    </Link>
               )}

               {!showContactus && (
                    <Link
                         to="contactus"
                         spy={true}
                         smooth={true}
                         offset={-100}
                         duration={500}
                    >
                         <Chip
                              value="Contact Us"
                              icon={<AtSymbolIcon size="1.5em" />}
                              variant={'ghost'}
                              className="flex items-center rounded-full dark:text-white dark:border-white ..."
                         />
                    </Link>
               )}
               {(showContactus || showInvestment) && (
                    <Link
                         to="home"
                         spy={true}
                         smooth={true}
                         offset={-100}
                         duration={500}
                    >
                         <Chip
                              value="Home"
                              icon={<IoHome size="1.5em" />}
                              variant={'ghost'}
                              className="flex items-center rounded-full dark:text-white dark:border-white ..."
                         />
                    </Link>
               )}
          </ul>
     );

     return (
          <Navbar className="sticky mx-auto max-w-screen-xl p-2 lg:rounded-xl h-max lg:pl-6 dark:bg-[#2E6494] dark:text-white dark:border-transparent">
               <div className="flex items-center justify-between text-blue-gray-900">
                    <img
                         className="h-10 w-66 rounded-lg object-cover object-center items-center"
                         src="/logo.png"
                         alt="nature image"
                    />
                    <div className="flex items-center justify-between gap-4 dark:text-white">
                         <div className="mr-4 hidden lg:block">{navList}</div>
                         <Tooltip
                              content={
                                   theme === 'dark'
                                        ? 'Toggle light mode'
                                        : 'Toggle dark mode'
                              }
                         >
                              <Button
                                   size="sm"
                                   variant="gradient"
                                   onClick={handleThemeChange}
                                   className="flex text-normal-case text-white gap-2 dark:hidden"
                              >
                                   {theme === 'dark' ? (
                                        <BsLightbulbFill size={'1.5em'} />
                                   ) : (
                                        <BsLightbulbOffFill size={'1.5em'} />
                                   )}
                              </Button>
                         </Tooltip>
                         <Tooltip content={'Toggle light mode'}>
                              <Button
                                   size="sm"
                                   variant="gradient"
                                   onClick={handleThemeChange}
                                   color="amber"
                                   className="hidden dark:inline-block dark:flex text-normal-case text-white gap-2"
                              >
                                   <BsLightbulbFill size={'1.5em'} />
                              </Button>
                         </Tooltip>
                         <Button
                              variant="gradient"
                              size="sm"
                              color="blue"
                              onClick={hanldeShowSignUp}
                              className="hover:bg-gradient-to-r from cyan-500 to-blue-500 hidden lg:inline-block lg:flex items-center gap-3"
                         >
                              <FaUserPlus size="1.5em" />
                              Sign up
                         </Button>
                         <Button
                              variant="gradient"
                              size="sm"
                              color="green"
                              onClick={handleShowLogin}
                              className="hidden lg:inline-block lg:flex items-center gap-3"
                         >
                              <GoPasskeyFill size="1.5em" />
                              Log in
                         </Button>
                         <IconButton
                              variant="text"
                              className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
                              ripple={false}
                              onClick={() => setOpenNav(!openNav)}
                         >
                              {openNav ? (
                                   <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        className="h-6 w-6"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                   >
                                        <path
                                             strokeLinecap="round"
                                             strokeLinejoin="round"
                                             d="M6 18L18 6M6 6l12 12"
                                        />
                                   </svg>
                              ) : (
                                   <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                   >
                                        <path
                                             strokeLinecap="round"
                                             strokeLinejoin="round"
                                             d="M4 6h16M4 12h16M4 18h16"
                                        />
                                   </svg>
                              )}
                         </IconButton>
                    </div>
               </div>
               <MobileNav open={openNav}>
                    {navList}
                    <div className="mb-2 flex items-center justify-center content-center text-center gap-4">
                         <Button
                              variant="gradient"
                              size="sm"
                              className="flex text-normal"
                         >
                              Dark mode
                         </Button>
                         <Button
                              variant="gradient"
                              size="sm"
                              color="blue"
                              onClick={hanldeShowSignUp}
                              className="flex"
                         >
                              Sign up
                         </Button>
                         <Button
                              variant="gradient"
                              size="sm"
                              color="green"
                              onClick={handleShowLogin}
                              className="flex"
                         >
                              Log in
                         </Button>
                    </div>
               </MobileNav>
          </Navbar>
     );
};

import Navbar from './Controls/App/navbar';
import Footer from './Controls/App/footer';
import Home from './Controls/Pages/home';
import ContactUs from './Controls/Pages/contactUs';
import LoginDialog from './Controls/Login/loginDialog';
import LoginsTab from './Controls/Login/loginsTab';
import SignUpDialog from './Controls/SignUp/signupDialog';
import InvestmentDesk from './Controls/Pages/investmentDesk';
import Reveal from './Controls/Common/revealAnimation';
import { RevealOnScroll } from './Controls/Common/revealOnScroll';

export const AppControls = {
     Navbar,
     Footer,
};

export const LoginControls = {
     LoginDialog,
     LoginsTab,
};

export const SignUpControls = {
     SignUpDialog,
};

export const Screen = {
     Home,
     ContactUs,
     InvestmentDesk,
};

export const CommonControls = {
     Reveal,
     RevealOnScroll,
};

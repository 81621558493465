/* eslint-disable import/no-anonymous-default-export */
import { Card, List, ListItem, Typography } from '@material-tailwind/react';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

export default () => {
     const control = useAnimation();
     const [ref, inView] = useInView();

     React.useEffect(() => {
          if (inView) {
               control.start('visible');
          } else {
               control.start('hidden');
          }
     }, [control, inView]);

     return (
          <>
               <Card
                    className="bg-transparent mx-auto rounded-lg shadow-sm mt-[40vw] gap-4"
                    id="investmentdesk"
               >
                    <div>
                         <motion.div
                              className="mt-6 flex justify-center ..."
                              variants={{
                                   initial: {
                                        opacity: 0,
                                        scale: 0,
                                   },
                                   animate: {
                                        opacity: 1,
                                        scale: 1,
                                   },
                              }}
                              whileInView={{ opacity: 1, scale: 1 }}
                              transition={{
                                   duration: 0.6,
                                   delay: 0.2,
                                   ease: 'circOut',
                              }}
                              initial="initial"
                              animate="animate"
                              viewport={{ once: false }}
                         >
                              <Typography
                                   variant="h3"
                                   className="mb-2 text-center text-blue-600/75"
                              >
                                   Investment Desk
                              </Typography>
                         </motion.div>
                         <motion.div
                              className="mt-6 flex justify-center ..."
                              variants={{
                                   initial: {
                                        opacity: 0,
                                        scale: 0,
                                   },
                                   animate: {
                                        opacity: 1,
                                        scale: 1,
                                   },
                              }}
                              whileInView={{ opacity: 1, scale: 1 }}
                              transition={{
                                   duration: 0.6,
                                   delay: 0.3,
                                   ease: 'circOut',
                              }}
                              initial="initial"
                              animate="animate"
                              viewport={{ once: false }}
                         >
                              <Typography className="dark:text-white text-center text-3xl">
                                   Everyones risk and return expectations are
                                   different, and so is our investment process.
                                   <br />
                                   We use a customised mix of the below
                                   instruments to optimise the risk reward ratio
                                   for each of our clients portfolios.
                              </Typography>
                         </motion.div>
                         <div className="mt-10 flex grid grid-auto-fit-lg justify-center shadow-lg rounded-lg gap-4...">
                              <List>
                                   <ListItem className="dark:text-white">
                                        Equity Shares
                                   </ListItem>
                                   <ListItem className="dark:text-white">
                                        Bonds & FD
                                   </ListItem>
                              </List>
                              <List>
                                   <ListItem className="text-center font-bold dark:text-white">
                                        Mutual Funds
                                   </ListItem>
                                   <ListItem className="dark:text-white">
                                        Pms
                                   </ListItem>
                                   <ListItem className="dark:text-white">
                                        Aif
                                   </ListItem>
                              </List>
                              <List>
                                   <ListItem className="dark:text-white">
                                        Equity Intraday
                                   </ListItem>
                                   <ListItem className="dark:text-white">
                                        F&O Derivatives
                                   </ListItem>
                              </List>
                         </div>
                    </div>
               </Card>
          </>
     );
};

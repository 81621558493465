/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { Button, Card, Typography } from '@material-tailwind/react';
import React from 'react';
import { GoPasskeyFill } from 'react-icons/go';
import { FaUserPlus } from 'react-icons/fa';
import { LoginControls, SignUpControls, CommonControls } from '../../index';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
     const [showLoginDialog, setShowLoginDialog] = React.useState(false);
     const [showSignUpDialog, setShowSignUpDialog] = React.useState(false);
     const control = useAnimation();
     const [ref, inView] = useInView();

     const title = 'Welcome!';

     React.useEffect(() => {
          if (inView) {
               control.start('visible');
          } else {
               control.start('hidden');
          }
     }, [control, inView]);

     const handleLogin = () => {
          setShowLoginDialog((cur) => !cur);
     };

     const handleSignUp = () => {
          setShowSignUpDialog((cur) => !cur);
     };

     const handleShowLoginDialog = (show) => {
          setShowLoginDialog(show);
     };

     const handleShowSignUpDialog = (show) => {
          setShowSignUpDialog(show);
     };

     return (
          <>
               <Card
                    className="bg-transparent mx-auto rounded-lg shadow-sm mt-10 gap-4"
                    id="home"
               >
                    <div>
                         <motion.div
                              className="flex justify-center ..."
                              variants={{
                                   initial: {
                                        opacity: 0,
                                        scale: 0,
                                   },
                                   animate: {
                                        opacity: 1,
                                        scale: 1,
                                   },
                              }}
                              whileInView={{ opacity: 1, scale: 1 }}
                              transition={{
                                   duration: 0.5,
                                   delay: 0.2,
                                   ease: 'circOut',
                              }}
                              initial="initial"
                              animate="animate"
                              viewport={{ once: false }}
                         >
                              <img
                                   className="h-64 w-1/2 xs:h-64 xs:w-full lg:object-cover rounded-lg xs:object-fill xs:flex xs:grow ..."
                                   src="/wkw-banner.png"
                                   alt="banner"
                              />
                         </motion.div>
                         <div className="mt-6 flex justify-center ...">
                              <Typography className="text-center items-start dark:text-white text-5xl">
                                   Welcome!
                              </Typography>
                         </div>
                         <motion.div
                              variants={{
                                   initial: {
                                        opacity: 0,
                                        scale: 0,
                                   },
                                   animate: {
                                        opacity: 1,
                                        scale: 1,
                                   },
                              }}
                              whileInView={{ opacity: 1, scale: 1 }}
                              transition={{
                                   duration: 0.6,
                                   delay: 0.3,
                                   ease: 'circOut',
                              }}
                              initial="initial"
                              animate="animate"
                              viewport={{ once: false }}
                              className="flex justify-center ..."
                         >
                              <Typography className="mt-16 text-center items-start dark:text-white text-3xl xs:text-lg">
                                   We specialize in providing agile, transparent
                                   and customized financial management services
                                   to our clients. In today's ever changing
                                   financial markets, we intend to leverage
                                   technology and our experience to simplify
                                   your portfolio management process..
                              </Typography>
                         </motion.div>
                         <motion.div
                              variants={{
                                   initial: {
                                        opacity: 0,
                                        y: 50,
                                   },
                                   animate: {
                                        opacity: 1,
                                        y: 0,
                                        type: 'spring',
                                   },
                              }}
                              whileInView={{ opacity: 1, scale: 1 }}
                              transition={{
                                   duration: 0.5,
                                   delay: 0.4,
                                   ease: 'anticipate',
                              }}
                              initial="initial"
                              animate="animate"
                              viewport={{ once: true }}
                              className="mt-20 flex justify-center gap-4 ..."
                         >
                              <Button
                                   variant="filled"
                                   color="green"
                                   size="lg"
                                   onClick={handleLogin}
                                   className="flex items-center gap-3"
                              >
                                   <GoPasskeyFill size="1.5em" />
                                   Log in
                              </Button>
                              <Button
                                   variant="filled"
                                   color="blue"
                                   size="lg"
                                   onClick={handleSignUp}
                                   className="flex items-center gap-3"
                              >
                                   <FaUserPlus size="1.5em" />
                                   Sign up
                              </Button>
                         </motion.div>
                    </div>
               </Card>
               {showLoginDialog && (
                    <LoginControls.LoginDialog
                         showDialog={handleShowLoginDialog}
                    />
               )}
               {showSignUpDialog && (
                    <SignUpControls.SignUpDialog
                         showDialog={handleShowSignUpDialog}
                    />
               )}
          </>
     );
};

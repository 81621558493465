import {
     AppControls,
     CommonControls,
     LoginControls,
     Screen,
     SignUpControls,
} from './Components';
import React from 'react';
import {
     motion,
     useTransform,
     useScroll,
     AnimatePresence,
} from 'framer-motion';

const fadeInAnimationVariants = {
     initial: {
          opacity: 0,
          y: 100,
     },
     animate: {
          opacity: 1,
          y: 0,
          transition: {
               delay: 0.05,
          },
     },
};

export default function App() {
     const [theme, setTheme] = React.useState(null);
     const [showLoginDialog, setShowLoginDialog] = React.useState(false);
     const [showSignUpDialog, setShowSignUpDialog] = React.useState(false);

     React.useEffect(() => {
          if (window.matchMedia('(prefers-color-schema:dark)').matches) {
               setTheme('dark');
          } else {
               setTheme('light');
          }
     }, []);

     React.useEffect(() => {
          if (theme === 'dark') {
               window.document.documentElement.classList.add('dark');
          } else {
               window.document.documentElement.classList.remove('dark');
          }
          localStorage.setItem('theme', theme);
     }, [theme]);

     const targetRef = React.useRef();
     const { scrollYProgress } = useScroll({
          target: targetRef,
          offset: ['end end', 'end start'],
     });

     const opacity = useTransform(scrollYProgress, [0, 0.5], [1, 0]);
     const scale = useTransform(scrollYProgress, [0, 0.5], [1, 0.8]);

     const handleThemeChange = () => {
          setTheme(theme === 'light' ? 'dark' : 'light');
     };

     const handleShowLoginDialog = (show) => {
          setShowLoginDialog(show);
     };

     const handleShowSignUpDialog = (show) => {
          setShowSignUpDialog(show);
     };

     return (
          <>
               <AnimatePresence>
                    <section className="min-h-screen bg-light-mode dark:bg-dark-mode bg-cover bg-no-repeat">
                         <div>
                              <AppControls.Navbar
                                   switchTheme={handleThemeChange}
                                   showLogin={handleShowLoginDialog}
                                   showSignUp={handleShowSignUpDialog}
                              />
                         </div>
                         <div className="flex justify-center ...">
                              <Screen.Home />
                         </div>
                         <CommonControls.RevealOnScroll>
                              <Screen.InvestmentDesk singlePage={false} />
                         </CommonControls.RevealOnScroll>
                         <CommonControls.RevealOnScroll>
                              <Screen.ContactUs
                                   singlePage={false}
                                   theme={theme}
                              />
                              {/* <AppControls.Footer /> */}
                         </CommonControls.RevealOnScroll>
                         <AppControls.Footer />
                    </section>
               </AnimatePresence>

               {showLoginDialog && (
                    <LoginControls.LoginDialog
                         showDialog={handleShowLoginDialog}
                    />
               )}
               {showSignUpDialog && (
                    <SignUpControls.SignUpDialog
                         showDialog={handleShowSignUpDialog}
                    />
               )}
          </>
     );
}

/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
     Card,
     Input,
     Checkbox,
     Button,
     Typography,
     Textarea,
} from '@material-tailwind/react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';
import React from 'react';

export default ({ theme }) => {
     const control = useAnimation();
     const [ref, inView] = useInView();

     React.useEffect(() => {
          if (inView) {
               control.start('visible');
          } else {
               control.start('hidden');
          }
     }, [control, inView]);
     return (
          <>
               <Card
                    className="bg-transparent rounded-lg shadow-sm mt-[40vw] items-center justify-center gap-4"
                    id="contactus"
               >
                    <div>
                         <motion.div
                              className="flex justify-center ..."
                              variants={{
                                   initial: {
                                        opacity: 0,
                                        scale: 0,
                                   },
                                   animate: {
                                        opacity: 1,
                                        scale: 1,
                                   },
                              }}
                              whileInView={{ opacity: 1, scale: 1 }}
                              transition={{
                                   duration: 0.5,
                                   delay: 0.2,
                                   ease: 'circOut',
                              }}
                              initial="initial"
                              animate="animate"
                              viewport={{ once: false }}
                         >
                              <Typography className="text-center items-start dark:text-white text-5xl">
                                   Write to us
                              </Typography>
                         </motion.div>
                         <motion.div
                              variants={{
                                   initial: {
                                        opacity: 0,
                                        scale: 0,
                                   },
                                   animate: {
                                        opacity: 1,
                                        scale: 1,
                                   },
                              }}
                              whileInView={{ opacity: 1, scale: 1 }}
                              transition={{
                                   duration: 0.6,
                                   delay: 0.3,
                                   ease: 'circOut',
                              }}
                              initial="initial"
                              animate="animate"
                              viewport={{ once: false }}
                              className="flex justify-center ..."
                         >
                              <Typography
                                   color="gray"
                                   className="mt-1 font-normal focus:outline-none dark:text-white"
                              >
                                   Enter your details to contact us.
                              </Typography>
                         </motion.div>
                         <div>
                              <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
                                   <div className="mb-4 flex flex-col gap-6">
                                        <Input
                                             size="lg"
                                             label="Name"
                                             color={'blue'}
                                        />
                                        <Input
                                             size="lg"
                                             label="Email"
                                             color={'blue'}
                                        />
                                        <Input
                                             size="lg"
                                             label="Phone Number"
                                             color={'blue'}
                                        />
                                        <Textarea
                                             size="lg"
                                             label="Your query"
                                             color={'blue'}
                                        />
                                   </div>
                                   <Checkbox
                                        label={
                                             <Typography
                                                  variant="small"
                                                  color="gray"
                                                  className="flex items-center font-normal dark:text-white"
                                             >
                                                  I agree the
                                                  <a
                                                       href="#"
                                                       className="font-medium transition-colors hover:text-gray-900 dark:text-white"
                                                  >
                                                       &nbsp;Terms and
                                                       Conditions
                                                  </a>
                                             </Typography>
                                        }
                                        color={
                                             theme === 'dark' ? 'white' : 'gray'
                                        }
                                        containerProps={{
                                             className: '-ml-2.5',
                                        }}
                                   />
                                   <Button
                                        className="mt-6 dark:bg-blue-500"
                                        fullWidth
                                   >
                                        Submit
                                   </Button>
                              </form>
                         </div>
                    </div>
               </Card>
          </>
     );
};

/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import {
     Button,
     Dialog,
     Card,
     CardHeader,
     CardBody,
     CardFooter,
     Typography,
} from '@material-tailwind/react';
import { LoginControls } from '../../index';

export default (props) => {
     const [open, setOpen] = React.useState(false);

     React.useEffect(() => {
          setOpen(true);
     }, []);

     const handleOpen = () => {
          setOpen((cur) => !cur);
          props.showDialog((cur) => !cur);
     };

     return (
          <>
               <Dialog
                    size="md"
                    open={open}
                    handler={handleOpen}
                    animate={{
                         mount: { scale: 1, y: 0 },
                         unmount: { scale: 0.9, y: -100 },
                    }}
                    className="bg-transparent shadow-none"
               >
                    <Card className="mx-auto w-full max-w-[30rem]">
                         <CardHeader
                              variant="gradient"
                              color="blue"
                              className="mb-4 grid h-28 place-items-center"
                         >
                              <Typography variant="h3" color="white">
                                   Log In
                              </Typography>
                         </CardHeader>
                         <CardBody className="flex grid grid-cols-2 gap-4">
                              <Button
                                   color="blue"
                                   onClick={() => {
                                        window.open(
                                             'https://whiteknightwealth.investwell.app/'
                                        );
                                   }}
                              >
                                   Portfolio Login
                              </Button>
                              <Button
                                   color="green"
                                   onClick={() => {
                                        window.open(
                                             'https://ttweb.indiainfoline.com/Trade/Login.aspx',
                                             '_blank'
                                        );
                                   }}
                              >
                                   Trade Login
                              </Button>
                         </CardBody>
                         <CardFooter className="pt-0 mt-8">
                              <Button
                                   variant="gradient"
                                   onClick={handleOpen}
                                   fullWidth
                                   ripple={true}
                                   className="rounded-full"
                              >
                                   Close
                              </Button>
                         </CardFooter>
                    </Card>
               </Dialog>
          </>
     );
};
